<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item style="cursor: pointer">
          <span @click="onBack">
            经销商管理
          </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="container">
      <div class="container-top">
        <a-row>
          <a-col :span="7">
            <span class="info-title">经销商类型：</span>
            {{ info.dealerType }}
          </a-col>
          <a-col :span="7">
            <span class="info-title">绑定时间：</span>
            {{ info.createTime }}
          </a-col>
          <a-col :span="7">
            <span class="info-title">合作状态：{{ info.cooperateResult }}</span>
          </a-col>
        </a-row>
      </div>
      <div class="container-middle">
        <a-row>
          <a-col><div class="info-title">基本信息</div></a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="7">统一社会信用代码：{{ info.orgCode }}</a-col>
          <a-col :span="7">机构名称：{{ info.dealerName }}</a-col>
          <a-col :span="7">法人姓名：{{ info.legalPerName }}</a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            经销商编码：{{ info.dealerCode ? info.dealerCode : "-" }}
          </a-col>
          <a-col :span="7">经销商地址：{{ dealerAddress }}</a-col>
        </a-row>
      </div>
      <div class="container-bottom">
        <a-row>
          <a-col><div class="info-title">联系人</div></a-col>
        </a-row>
        <a-row>
          <a-col :span="20">
            <a-table
              class="per-table"
              :data-source="tblData"
              :columns="tblColumns"
              :pagination="pagination"
              :loading="loading"
              row-key=""
            >
              <a-space slot="action" slot-scope="record">
                <span class="btn-operation" @click="openModifyModal(record)">
                  修改
                </span>
              </a-space>
            </a-table>
          </a-col>
        </a-row>
      </div>
    </div>

    <a-modal v-model="modifyVisible" title="修改联系人信息" @ok="onModify">
      <a-form :form="modifyForm">
        <a-form-item label="联系人姓名">
          <a-input
            v-decorator="['perName', { rules: [{ required: true }] }]"
            class="input"
          />
        </a-form-item>
        <a-form-item label="联系人电话">
          <a-input
            v-decorator="['perTel', { rules: [{ required: true }] }]"
            class="input"
          />
        </a-form-item>
        <a-form-item label="联系地址">
          <a-cascader
            v-model="perAreaCode"
            :options="areaTreeData"
            :field-names="areaTreeNames"
            placeholder="请选择区域"
            @change="onChangePer"
          />
          <a-input
            v-decorator="[
              'perDetailAddress',
              {
                rules: [{ required: true, message: '请输入联系地址' }]
              }
            ]"
            placeholder="请输入详细地址"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import {
  fetchDealerDetail,
  loadArea,
  modifyContact
} from "@/services/DealerService";
import moment from "moment";

const tblColumns = [
  {
    title: "姓名",
    dataIndex: "realName"
  },
  {
    title: "电话",
    dataIndex: "mobile"
  },
  {
    title: "地址",
    dataIndex: "detailAddress"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];

export default {
  data() {
    return {
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      modifyVisible: false,
      modifyForm: this.$form.createForm(this),
      perAreaCode: [],
      areaTreeData: [],
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      },
      factoryVenuId: this.$route.params.factoryVenuId,
      info: {
        dealerContactVO: {
          orgCode: "",
          dealerName: "",
          legalPersonName: "",
          orgDetailAddress: ""
        },
        factoryDealerVO: {
          createTime: "",
          cooperateStatus: "",
          dealerType: ""
        }
      },
      modifyInfo: {},
      dealerAddress: ""
    };
  },
  mounted() {
    this.onLoadArea();
    this.onDetail();
  },
  methods: {
    onBack() {
      this.$router.push({ name: "DealerManage" });
    },
    // 打开修改弹窗
    openModifyModal(record) {
      this.modifyVisible = true;
      this.modifyInfo = {
        dealerId: record.dealerId,
        dealerName: record.dealerName,
        mobile: record.personTel,
        orgCode: record.orgCode,
        userId: record.contactId,
        userName: record.realName,
        address: {
          provinceCOde: record.provinceCode,
          cityCode: record.cityCode,
          countyCode: record.countyCode,
          detailAddress: record.detailAddress
        }
      };
      setTimeout(() => {
        this.modifyForm.setFieldsValue({
          perName: record.realName,
          perTel: record.mobile,
          perDetailAddress: record.detailAddress
        });
        if (
          record.provinceCode !== null &&
          record.cityCode !== null &&
          record.countyCode !== null
        ) {
          this.perAreaCode = [
            record.provinceCode,
            record.cityCode,
            record.countyCode
          ];
        }
      }, 100);
    },
    onModify() {
      this.modifyForm.validateFields(error => {
        if (!error) {
          if (this.perAreaCode.length < 3) {
            this.$message.error("请选择区域");
            return;
          } else if (this.modifyForm.getFieldsValue().perTel.length !== 11) {
            this.$message.info("请输入正确的手机号");
          } else {
            const params = {
              dealerId: this.modifyInfo.dealerId,
              dealerName: this.modifyInfo.dealerName,
              mobile: this.modifyForm.getFieldsValue().perTel,
              orgCode: this.modifyInfo.orgCode,
              userId: this.modifyInfo.userId,
              userName: this.modifyForm.getFieldsValue().perName,
              address: {
                provinceCode: this.perAreaCode[0],
                cityCode: this.perAreaCode[1],
                countyCode: this.perAreaCode[2],
                detailAddress: this.modifyForm.getFieldsValue().perDetailAddress
              }
            };
            modifyContact(params).then(resp => {
              if (resp.data.code === "FAILED") {
                this.$message.error(resp.data.errorMsg);
              } else {
                this.$message.success("修改成功");
                this.modifyVisible = false;
                this.onDetail();
              }
            });
          }
        }
      });
    },
    onChangePer(value) {
      this.perAreaCode = value;
    },
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        } else {
          console.log(resp.data.errorMsg);
        }
      });
    },
    onDetail() {
      fetchDealerDetail(this.factoryVenuId).then(resp => {
        if (resp.data.code === "FAILED") {
          this.$message.error(resp.data.errormsg);
        } else {
          const data = resp.data.data;
          if (data.orgProvinceName === data.orgCityName) {
            this.dealerAddress = `${data.orgCityName} ${data.orgCountyName} ${data.orgDetailAddress}`;
          } else {
            this.dealerAddress = `${data.orgProvinceName} ${data.orgCityName} ${data.orgCountyName} ${data.orgDetailAddress}`;
          }
          if (data.storeType) {
            data.dealerType = `${data.dealerType}（${data.storeType}）`;
          }
          data.createTime = moment(data.createTime).format("YYYY-MM-DD");
          this.tblData = [];
          if (data.contactList.length > 0) {
            this.tblData.push(data.contactList[0]);
          }
          this.info = data;
        }
      });
    }
  }
};
</script>

<style scoped>
.container {
  margin: 10px 20px;
}
.container-top,
.container-middle,
.container-bottom {
  box-sizing: border-box;
  padding: 20px 30px;
  background-color: white;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
}
.container-middle {
  margin: 10px 0;
}
.info-title {
  font-weight: bold;
}
.row {
  margin: 20px 0;
}
.per-table {
  margin-top: 20px;
}
.btn-operation {
  cursor: pointer;
}
.input {
  width: 200px;
}
.ant-row {
  display: flex !important;
}
/deep/ .ant-form-item .ant-form-item-label {
  min-width: 100px;
}
</style>
